import { instance } from '@/api/index';

function idDupCheck(data) {
	return instance.get('/users/idDupCheck', {params : data});
}

function searchAdr(data) {
	return instance.get('/users/searchAdr', {params : data});
}

function join(data) {
	return instance.post('/users/join', data);
}

function findPwd(data){
    return instance.post('/users/findPwd', data);
}
function refreshUser(param) {
	return instance.get("/auth/refresh", { params: param });
}

export { idDupCheck, searchAdr, join, findPwd, refreshUser };

