<template>
  <div>
    <nav class="navbar navbar-inverse">
      <div class="container-fluid">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <RouterLink class="navbar-brand" to="/" @click="init">
            <img class="nicereport_ci" src="@/assets/images/nicereport_ci.png" alt="carousel01" />
          </RouterLink>
        </div>
        <div class="collapse navbar-collapse" id="myNavbar">
          <ul class="nav navbar-nav">
            <li id="menu01" :class="{active : activeArr[0]}" @click="visibleTab(0)">
              <RouterLink to="/cmp/dmiCmp">국내기업보고서</RouterLink>
            </li>
            <li id="menu02" class="dropdown" :class="{active : activeArr[1]}" @click="visibleTab(1)">
              <a class="dropdown-toggle" data-toggle="dropdown" >
                해외기업보고서
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li id="menu02_01" @click="visibleTab(1)">                  
                  <RouterLink :to="{name: 'Foreign', params: {id:1}}">해외기업보고서</RouterLink>
                </li>
                <li id="menu02_02" @click="visibleTab(1)">                  
                  <RouterLink :to="{name: 'Foreign', params: {id:2}}">국가보고서</RouterLink>
                </li>
                <!-- <li id="menu02_03"><a href="/word_ntt/01.do?mnTy=03">해외바이어정보</a></li> -->
              </ul>
            </li>

            <li id="menu03" :class="{active : activeArr[2]}" @click="visibleTab(2)">
              <!-- <a href="/data_ntt/01.do">신설법인정보</a> -->
              <RouterLink to="/corp">신설법인정보</RouterLink>
            </li>
            <li id="menu04" :class="{active : activeArr[3]}" @click="visibleTab(3)">              
               <RouterLink to="/mypage">My Page</RouterLink>
            </li>

            <!-- <li><a href="http://eng.nicereport.com/" target="_blank"><span class="glyphicon glyphicon-font"></span> English</a></li> -->
          </ul>

          <form
            class="navbar-form navbar-right"
            role="search"
            name="frm_u_search"
            @submit.prevent="goSearch"
          >
            <div class="input-group">
              <input
                type="text"
                id="scr_val"
                name="scr_val"                                
                class="form-control"
                placeholder="기업명 또는 사업자번호를 입력하세요"
                v-model="scr_val"
                @keydown.enter="goSearch"
                autocomplete="off"
              />
              <span class="input-group-btn">
                <button class="btn btn-warning" type="button" @click="goSearch">Search</button>
              </span>
            </div>
          </form>

          <ul class="nav navbar-nav navbar-right">
            <!-- <li><a href="http://eng.nicereport.com/" target="_blank"><span class="glyphicon glyphicon-font"></span> English</a></li> -->
            <!--li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="glyphicon glyphicon-log-in"></span> Login</a></li-->
            <!-- ${if(!login)}$ -->
            <!-- <li><a href="javascript:go_loginTop()"><span class="glyphicon glyphicon-user"></span> Sign Up</a></li>
				<li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="glyphicon glyphicon-log-in"></span> Login</a></li>
            <li><a href="/contents_ntt/step1_checkresult_nr.do" target="blank" class="guest-buy"><span class="glyphicon glyphicon-shopping-cart"></span> 비회원구매</a></li>-->
            
            <li v-if="this.isLogin!=false">
              <a data-toggle="modal" @click="userLogout">
                <span class="glyphicon glyphicon-log-out"></span> Logout
              </a>
            </li>            
          </ul>
        </div>
      </div>
    </nav>
    <!-- /Navigation -->
    <!-- Login Modal content -->
    <div id="loginModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Sign up with E-mail</h4>
          </div>
          <div class="modal-body">
            <form role="form" name="modal_login" method="post" action="/dnbrNewWork.do">
              <input type="hidden" name="func" value="dnbrNewMnu.login" />
              <div class="form-group"> 
                <label for="id">ID :</label>
                <input type="id" class="form-control" name="id" />
              </div>
              <div class="form-group">
                <label for="pwd">Password :</label>
                <input type="password" class="form-control" name="pw"/>
              </div>
              <button type="submit" class="btn btn-primary btn-block" onclick="loginTop()">Login</button>
            </form>
          </div>
          <div class="modal-body">
            <button type="button" class="btn btn-warning" onclick="go_loginTop()">Not a member yet</button>
            <button type="button" class="btn btn-danger">Forgot your ID or Password</button>
            <!-- <button type="button" class="btn btn-info" >As a Guest</button> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Forgot your Password Modal content -->
    <div id="forgotModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Forgot your Password</h4>
          </div>
          <div class="modal-body">
            <form role="form">
              <div class="form-group">
                <label for="email">Email address :</label>
                <input type="email" class="form-control" id="email" />
              </div>
              <button type="submit" class="btn btn-primary btn-block">Forgot your Password</button>
            </form>
          </div>
          <div class="modal-body">
            <button type="button" class="btn btn-warning">Resister</button>
            <button type="button" class="btn btn-info floatR">Log in</button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Sign UP Modal content -->
    <div id="signupModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Sign up</h4>
          </div>
          <div class="modal-body">
            <form role="form">
              <div class="form-group">
                <label for="name">Name :</label>
                <input type="name" class="form-control" id="name" />
              </div>
              <div class="form-group has-success">
                <label class="control-label" for="email">Email address :</label>
                <input type="email" class="form-control" id="email" />
              </div>
              <div class="form-group has-error">
                <label class="control-label" for="pwd">Password :</label>
                <input type="password" class="form-control" id="pwd" />
              </div>
              <div class="form-group has-warning">
                <label class="control-label" for="confpwd">Confirm Password :</label>
                <input type="password" class="form-control" id="conpwd" />
              </div>

              <div class="form-group">
                <label for="sel1">Country of resistance :</label>
                <select class="form-control" id="sel1">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" /> Agree All
                  <font>(Includes Optional Categories)</font>
                </label>
              </div>
              <div class="agree-category">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" /> Agree to the Terms of Service
                    <font>(Mandatory)</font>
                  </label>
                  <button type="button" class="btn btn-link btn-xs">View</button>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" /> Privacy Policy
                    <font>(Mandatory)</font>
                  </label>
                  <button type="button" class="btn btn-link btn-xs">View</button>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" /> Information regarding events and services
                    <font>(Optional)</font>
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-body">
            <button type="submit" class="btn btn-primary btn-block">Sign Up</button>
            <p class="mt10">
              Already a Member?
              <button type="button" class="btn btn-link btn-sm">Log In</button>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { isSpecial } from "@/api/common/common";
export default {
  data() {
      return {
        activeArr : [false, false, false, false],
        scr_val : '',
      }
    },    
  // mounted() {      
  //     eventActiveMnu()
  // },
  computed: {  
    ...mapGetters("userStore", ["isLogin"]),    
  },  
  watch:{
     // 라우터 변경 감지
     // 헤더 메뉴의 활성화 상태 변경
    '$route'(to, from) {      
      this.init();
      if(to.fullPath == '/cmp/dmiCmp'|| to.fullPath =='/cmp/dmiCmpDetail'){
        this.activeArr[0] = true;
      }else if(to.fullPath == '/forCmpRpt/2'||to.fullPath == '/forCmpRpt/1'){
        this.activeArr[1] = true;
      }else if(to.fullPath == '/corp'){
        this.activeArr[2] = true;
      }else if(to.fullPath == '/mypage'|| to.fullPath == '/shoppingCart'|| to.fullPath == '/myPurchaseHistory'|| to.fullPath == '/upAlert'|| to.fullPath == "/memberModifyPre"|| to.fullPath == "/addCash"){
        this.activeArr[3] = true;
      }
      // 현재 경로에 따라서 활성화된 메뉴를 업데이트
      // if (to.name) {
      //   this.activeMenu = this.getMenuIndex(to.name);
      // }
    }
  },
  methods: {    
    ...mapActions("cmpStore", ["GET_CMP_LIST","SET_SEARCH_WORD"]),    
    goEnter() {
      if (event.keyCode == 13) {
        goSearch();
      }
    },
    async goSearch() {      
      if (isSpecial(this.scr_val)) {
        alert("특수문자가 허용되지 않습니다.");
        return;
      }
      if(this.scr_val.length == 0){
        this.$router.push('/cmp/dmiCmp');//검색페이지로 이동
        this.SET_SEARCH_WORD(this.scr_val);
      }
      // 기업 검색
      var param= {
        iPage: 1,
        cmpNm: this.scr_val,
      };
      await this.GET_CMP_LIST(param);
      this.$router.push({path:'/cmp/dmiCmp'});
    },
    eventActiveMnu() {            
      url = $(location).attr("href");
      $("[id^=menu]").removeClass("active");
      
      if (url.indexOf("korea_ntt") > 0) {
        $("#menu01").addClass("active");
      } else if (url.indexOf("word_ntt") > 0) {
        $("#menu02").addClass("active");
        if (url.indexOf("foreign") > 0) {
          $("#menu02_01").addClass("active");
          $("#ftab1").click();
        } else if (url.indexOf("mnTy=02") > 0) {
          $("#menu02_02").addClass("active");
          $("#ftab2").click();
        } else if (url.indexOf("mnTy=03") > 0) {
          $("#menu02_03").addClass("active");
          $("#ftab3").click();
        }
      } else if (url.indexOf("data_ntt") > 0) {
        $("#menu03").addClass("active");
      } else if (url.indexOf("mypage_ntt") > 0) {
        $("#menu04").addClass("active");
      }
    },
    visibleTab(val){
      for(var index=0; index<4; index++){
        if(val==index) this.activeArr[index] = true;
        else{
          this.activeArr[index] = false;
        }
      }
    },
    userLogout() {
      this.$store.dispatch("userStore/LOGOUT");
      this.$router.push("/");      
    },
    init() {
      this.activeArr = [false, false, false, false];
    },
  },
};
</script>