<template>
  <div id="app">
    <Header v-if="this.mountHeader" />
    <AdminHeader v-if="this.mountAdminHeader" />
    <AdminHeader2 v-if="this.mountAdminHeader2" />
    <div class="app__contents">
      <RouterView />
    </div>
    <Footer v-if="this.mountFooter" />
    <AdminFooter v-if="this.mountAdminFooter" />
  </div>
</template>

<script>


import Header from "@/components/main/Header.vue";
import Footer from "@/components/main/Footer.vue";
import AdminHeader from "@/components/main/AdminHeader.vue";
import AdminHeader2 from "@/components/main/AdminHeader2.vue";
import AdminFooter from "@/components/main/AdminFooter.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    AdminHeader,
    AdminHeader2,
    AdminFooter
  },
  computed: {
    mountHeader() {
      return (
        this.$route.name !== "idDupCheck" &&
        this.$route.name !== "adrSearch" &&
        this.$route.name !== "indSearch" &&
        this.$route.name !== "admin" &&
        this.$route.name !== "dnbman" &&
        this.$route.name !== "order" &&
        this.$route.name !== "buyerInfo" &&
        this.$route.name !== "orderInfo" &&
        this.$route.name !== "cancel" &&
        this.$route.name !== "member" &&
        this.$route.name !== "memberInfo" &&
        this.$route.name !== "buyHistory" &&
        this.$route.name !== "cashHistory" &&
        this.$route.name !== "updateAlarm" &&
        this.$route.name !== "buyLog" &&
        this.$route.name !== "secret" &&
        this.$route.name !== "inputSecret" &&
        this.$route.name !== "cpMonth" &&
        this.$route.name !== "cpAdmin" &&
        this.$route.name !== "incorpExcelDownload" &&
        this.$route.name !== "ozStart" &&
        this.$route.name !== "404" &&
        this.$route.name !== "guestcmpSearch" &&
        this.$route.name !== "guestcmpDetail" &&
        this.$route.name !== "guestContent" &&
        this.$route.name !== "guestContent_NV" &&
        this.$route.name !== "guestOzView" &&
        this.$route.name !== "reportInfo" &&
        this.$route.name !== "dunsHelp"
      );
    },
    mountFooter() {
        return (
        this.$route.name !== "idDupCheck" &&
        this.$route.name !== "adrSearch" &&
        this.$route.name !== "indSearch" &&
        this.$route.name !== "admin" &&
        this.$route.name !== "dnbman" &&
        this.$route.name !== "order" &&
        this.$route.name !== "buyerInfo" &&
        this.$route.name !== "orderInfo" &&
        this.$route.name !== "cancel" &&
        this.$route.name !== "member" &&
        this.$route.name !== "memberInfo" &&
        this.$route.name !== "buyHistory" &&
        this.$route.name !== "cashHistory" &&
        this.$route.name !== "updateAlarm" &&
        this.$route.name !== "buyLog" &&
        this.$route.name !== "secret" &&
        this.$route.name !== "inputSecret" &&
        this.$route.name !== "cpMonth" &&
        this.$route.name !== "cpAdmin" &&
        this.$route.name !== "incorpExcelDownload" &&
        this.$route.name !== "ozStart" &&
        this.$route.name !== "guestOzView" &&
        this.$route.name !== "reportInfo" &&
        this.$route.name !== "dunsHelp" &&
        this.$route.name !== "404"
      );
    },
    mountAdminHeader() {
      return (
        this.$route.name == "dnbman" ||
        this.$route.name == "order" ||
        this.$route.name == "member" ||
        this.$route.name == "memberInfo" ||
        this.$route.name == "buyHistory" ||
        this.$route.name == "cashHistory" ||
        this.$route.name == "updateAlarm" ||
        this.$route.name == "buyLog" ||
        this.$route.name == "secret" ||
        this.$route.name == "inputSecret"
      );
    },
    mountAdminHeader2() {
      return (
        this.$route.name == "cpMonth" ||
        this.$route.name == "cpAdmin"
      );
    },
    mountAdminFooter() {
      return (
        this.$route.name == "dnbman" ||
        this.$route.name == "order" ||
        this.$route.name == "member" ||
        this.$route.name == "memberInfo" ||
        this.$route.name == "buyHistory" ||
        this.$route.name == "cashHistory" ||
        this.$route.name == "updateAlarm" ||
        this.$route.name == "buyLog" ||
        this.$route.name == "secret" ||
        this.$route.name == "secinputSecretret"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "@/assets/css/common/ui-lightness/jquery-ui-1.8.1.custom.css";
  @import "@/assets/css/common/bootstrap-theme.css";
  @import "@/assets/css/common/bootstrap.css";
  @import "@/assets/css/common/nicereport.css";
  @import "@/assets/css/common/margin.css";
  @import "@/assets/css/common/jquery-ui.min.css";
  @import "@/assets/css/common/style.css";
</style>
