<template>
  <!-- Footer content -->
  <div class="container">
    <div class="footer col-sm-12">
      <div class="col-sm-8 row">
        <div class="footer-link">
          <a href="http://www.nicednb.com/" target="_blank">회사소개</a>
          <span>|</span>
          <a href="https://www.nicereport.net/ntt_main.do" class="personal">회원이용약관</a>
          <span>|</span>
          <!--<a href="https://www.nicereport.net/ntt_main.do">개인정보 수집 및 이용동의 </a><span>|</span>-->
          <a
            style="color: #FD5308"
            href="https://www.nicereport.net/ntt_main.do#"
            onclick="window.open(&#39;http://www.nicednb.com/etc_pop/privacy_pop.html&#39;, &#39;etc_popup&#39;, &#39;left=50,top=50,width=680,height=650,scrollbars=1&#39;);"
          >개인정보처리방침</a>
          <span>|</span>
          <a href="https://www.nicereport.net/ntt_main.do">법적근거</a>
        </div>
        <div class="footer-info">
          <span>NICE D&amp;B Co., Ltd.</span>
          <span>|</span>
          <span>대표자 : 오규근</span>
          <span>|</span>
          <span>(04535) 서울특별시 중구 소공로 70, 포스트타워 B동 16층</span>
          <span>|</span>
          <span>통신판매업신고 : 제2012-서울마포-0168호</span>
          <br />
          <span>TEL : 02-2122-2536</span>
          <span>|</span>
          <span>FAX : 02-2122-2335</span>
          <span>|</span>
          <span>
            E-mail :
            <a href="mailto:nicereport@nicednb.com">nicereport@nicednb.com</a>
          </span>
        </div>
        <div class="footer-copyright">
          <span>COPYRIGHT 2006 NICE D&amp;B Co., Ltd ALL RIGHTS RESERVED.</span>
        </div>
      </div>
      <div class="col-sm-4">
        <!-- isms-wrap -->
        <div class="isms-wrap">
          <div class="isms-logo">
            <img alt="ISMS인증획득" src="@/assets/images/logo-isms.png" />
          </div>
          <div class="isms-text">
            <strong>[인증범위]</strong> 기업신용정보 서비스 등 전 사업부문
            <br />
            <strong>[유효기간]</strong> 2022년 11월 28일 ~ 2025년 11월 27일
          </div>
        </div>
        <!-- //isms-wrap -->
      </div>
    </div>
  </div>
</template>