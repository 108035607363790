import { instance, blobResponseInstanceWithAuth } from '@/api/index';

function adminLogin(userData) {
	return instance.post('/admin/adminLogin', userData);
}

function adminMain(adminData) {
	return instance.post('/admin/dnbman', adminData);
}

function adminOrder(orderData) {
	return instance.post('/admin/order', orderData);
}

function buyerInfo(params) {
	return instance.get('/admin/buyerInfo', { params: params });
}

function orderInfo(params) {
	return instance.get('/admin/orderInfo', { params: params });
}

function cancel(params) {
	return instance.get('/admin/cancel', { params: params });
}

function adminMember(memberData) {
	return instance.post('/admin/member', memberData);
}
function memberInfo(params) {
	return instance.get('/admin/memberInfo', { params: params });
}
function buyHistory(params) {
	return instance.get('/admin/buyHistory', { params: params });
}
function cashHistory(params) {
	return instance.get('/admin/cashHistory', { params: params });
}
function cashUpdate(cash) {
	return instance.post('/admin/cashUpdate', cash);
}
function updateAlarm(params) {
	return instance.get('/admin/updateAlarm', { params: params });
}
function buyLog(bugLogData) {
	return instance.post('/admin/buyLog', bugLogData);
}
function secret(secretData) {
	return instance.post('/admin/secret', secretData);
}
function inputSecret(secretData) {
	return instance.post('/admin/inputSecret', secretData);
}
function cpMonth(searchData) {
	return instance.post('/admin/cpMonth', searchData);
}

function cpAdmin(searchData) {
	return instance.post('/admin/cpAdmin', searchData);
}

function cpAdminExcel(searchData) {
	return blobResponseInstanceWithAuth.post('/admin/cpAdminExcel', searchData);
}


export { adminLogin, adminMain, adminOrder, buyerInfo, orderInfo, cancel, adminMember, memberInfo
	   , buyHistory, cashHistory, cashUpdate, updateAlarm, buyLog, secret, inputSecret, cpMonth, cpAdmin, cpAdminExcel };
