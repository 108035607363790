import { loginUser } from '@/api/auth';
import { adminLogin } from '@/api/admin';
import VueCookies from "vue-cookies";
const userStore = {
	namespaced: true,
	state: {
		userId: VueCookies.get("userId") || "",
		userGrade: VueCookies.get("userGrade") || "",
		ndbTkn: VueCookies.get("ndbTkn") || "",
	},
	getters: {
		isLogin(state) {
			return state.ndbTkn !== "";
		},
		getId(state) {
			return state.id;
		},
		getUserGrade(state) {
			return state.userGrade;
		},
		getNdbTkn(state) {
			return state.ndbTkn;
		},
	},
	mutations: {
		SET_ID(state, id) {
			state.userId = id;
		},
		SET_USER_GRADE(state, userGrade) {
			state.userGrade = userGrade;
		},
		SET_NDB_TKN(state, ndbTkn) {
			state.ndbTkn = ndbTkn;
		},
		CLEAR_ALL(state) {
			state.userId = "";
			//state.userGrade = "";
			state.ndbTkn = "";
		},
	},
	actions: {
		async LOGIN({ commit }, loginUserData) {
			const { data } = await loginUser(loginUserData);
			commit("SET_ID", data.data.id);
			//commit("SET_USER_GRADE", data.data.userGrade);
			commit("SET_NDB_TKN", data.data.ndbTkn);
			VueCookies.set("userId", data.data.id);
			//VueCookies.set("userGrade", data.data.userGrade);
			VueCookies.set("ndbTkn", data.data.ndbTkn);
		},
		LOGOUT({ commit }) {
			commit("CLEAR_ALL");
			VueCookies.remove("userId");
			//VueCookies.remove("userGrade");
			VueCookies.remove("ndbTkn");
		},		
		async ADMIN_LOGIN({ commit }, loginUserData) {
			const { data } = await adminLogin(loginUserData);
			commit("SET_ID", data.data.id);
			//commit("SET_USER_GRADE", data.data.userGrade);
			commit("SET_NDB_TKN", data.data.ndbTkn);
			VueCookies.set("userId", data.data.id);
			//VueCookies.set("userGrade", data.data.userGrade);
			VueCookies.set("ndbTkn", data.data.ndbTkn);
			return data.data.redirectUrl
		},
		REFRESH_USER({ commit }, data) {
			debugger;
			commit("SET_NDB_TKN", data.data.ndbTkn);
			VueCookies.set("ndbTkn", data.data.ndbTkn);
		}
	},
};

export default userStore;
