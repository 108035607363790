function formatDate(dateString) {
	const today = new Date(dateString);
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}

function formatDateKor(dateString) {
	//debugger;
	//const today = dateString;
	if (dateString == null || dateString == '') return;
	dateString = dateString.replace(/-/g, '');
	const year = dateString.substring(0, 4);
	const month = dateString.substring(4, 6);
	const day = dateString.substring(6, 8);

	return `${year}년${month}월${day}일`;
}

function formatDateCommon(dateString) {
	//debugger;
	//const today = dateString;
	if (dateString == null || dateString == '') return;
	dateString = dateString.replace(/-/g, '');
	const year = dateString.substring(0, 4);
	const month = dateString.substring(4, 6);
	const day = dateString.substring(6, 8);

	return `${year}-${month}-${day}`;
}

function toDay() {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}

function validDate(fromDT, toDT) {
	let valid = true;

	if ((fromDT == null && toDT == null) || (fromDT == "" && toDT == "")) {
		alert("날짜를 지정해주세요.");
		valid = false;
	} else if ((fromDT == null || fromDT == "") && toDT != null) {
		alert("시작 날짜를 지정해주세요.");
		valid = false;
	} else if (fromDT != null && (toDT == null || toDT == "")) {
		alert("끝 날짜를 지정해주세요.");
		valid = false;
	} else if (fromDT > toDT) {
		alert("시작 날짜가 끝 날짜보다 큰 값입니다.");
		valid = false;
	}

	return valid;
}

/***두개의 날짜사이의 차이를 월 단위 숫자로 리턴하기***/
function calcDateDiff(val1, val2) {
	const year1 = val1.substring(0, 4);
	const month1 = val1.substring(5, 7);
	const day1 = val1.substring(8, 10);
	const year2 = val2.substring(0, 4);
	const month2 = val2.substring(5, 7);
	const day2 = val2.substring(8, 10);
	const date1 = new Date(year1, month1, day1);
	const date2 = new Date(year2, month2, day2);

	return ((date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24 / 31);
}

/* String 숫자 천단위마다 ,찍어줌 */
function formatNumber(numberString) {
	return Intl.NumberFormat().format(numberString);
}

function isSpecial(checkStr) {
	var checkOK = "~`':;{}[]<>,.!@#$%^&*()_+|\\/?";

	for (var i = 0; i < checkStr.length; i++) {
		var ch = checkStr.charAt(i);
		for (var j = 0; j < checkOK.length; j++) {
			if (ch == checkOK.charAt(j)) { return true; break; }
		}
	}
	return false;
}

function formatPhoneNumber(phoneInput) {
	const regex = /^(010)(\d{3,4})(\d{2,4})$/;
	const match = phoneInput.match(regex);
	let formattedPhoneNumber = "";

	if (match) {
		if (match[3].length === 4) {
			formattedPhoneNumber = `${match[1]}-${match[2]}-${match[3]}`;
		} else if (match[3].length === 3) {
			formattedPhoneNumber = `${match[1]}-${match[2].slice(0, 3)}-${match[2].slice(3)}${match[3]}`;
		}
	} else {
		formattedPhoneNumber = '유효하지 않은 전화번호 형식입니다.';
	}

	return formattedPhoneNumber;
}

function isBusiNoByValue(strNo) {

	var sum = 0;
	var getlist = new Array(10);
	var chkvalue = new Array("1", "3", "7", "1", "3", "7", "1", "3", "5");

	for (var i = 0; i < 10; i++) {
		getlist[i] = strNo.substring(i, i + 1);
	}

	for (var i = 0; i < 9; i++) {
		sum += getlist[i] * chkvalue[i];
	}

	sum = sum + parseInt((getlist[8] * 5) / 10);
	sidliy = sum % 10;
	sidchk = 0;

	if (sidliy != 0) {
		sidchk = 10 - sidliy;
	}
	else {
		sidchk = 0;
	}

	if (sidchk != getlist[9]) {
		return false;
	}
	return true;
}
export { isBusiNoByValue, formatDate, toDay, validDate, calcDateDiff, formatNumber, isSpecial, formatPhoneNumber, formatDateKor, formatDateCommon };
