<template>
	<div id="adminHeader">
		<div class="headerWrap">
			<div class="heading">
				<a href="/dnbman/index"><h1><img src="@/assets/images/admin/DNBREPORT_LOGO.gif" style="top: 25px; position: absolute;"></h1></a>
			</div>
			<ul>
				<li><a href="https://pg.nicepay.co.kr" target="_blank">나이스페이</a></li>
				<li><a href="http://agent.happycampus.com/">해피캠퍼스</a></li>
				<li><a href="http://cp.reportshop.co.kr/login.php">레포트샵</a></li>
				<li class="last"><a @click="userLogout">로그아웃</a></li>
			</ul>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { isSpecial } from "@/api/common/common";
export default {
  data() {
      return {
        activeArr : [false, false, false, false],
        scr_val : '',
      }
    },
  // mounted() {      
  //     eventActiveMnu()
  // },
  computed: {  
  ...mapGetters("userStore", ["isLogin"]),
  },
  methods: {    
    ...mapActions("cmpStore", ["GET_CMP_LIST"]),
    goEnter() {
      if (event.keyCode == 13) {
        goSearch();
      }
    },
    async goSearch() {      
      if (isSpecial(this.scr_val)) {        
        alert("특수문자가 허용되지 않습니다.");
        return;
      }
      // 기업 검색
      var param= {
        iPage: 1,
        cmpNm: this.scr_val,
      };
      await this.GET_CMP_LIST(param);
      this.$router.push({path:'/cmp/dmiCmp'});
    },
    eventActiveMnu() {            
      url = $(location).attr("href");
      $("[id^=menu]").removeClass("active");
      
      if (url.indexOf("korea_ntt") > 0) {
        $("#menu01").addClass("active");
      } else if (url.indexOf("word_ntt") > 0) {
        $("#menu02").addClass("active");
        if (url.indexOf("foreign") > 0) {
          $("#menu02_01").addClass("active");
          $("#ftab1").click();
        } else if (url.indexOf("mnTy=02") > 0) {
          $("#menu02_02").addClass("active");
          $("#ftab2").click();
        } else if (url.indexOf("mnTy=03") > 0) {
          $("#menu02_03").addClass("active");
          $("#ftab3").click();
        }
      } else if (url.indexOf("data_ntt") > 0) {
        $("#menu03").addClass("active");
      } else if (url.indexOf("mypage_ntt") > 0) {
        $("#menu04").addClass("active");
      }
    },
    visibleTab(val){
      for(var index=0; index<4; index++){
        if(val==index) this.activeArr[index] = true;
        else{
          this.activeArr[index] = false;
        }
      }
    },
    userLogout() {
      this.$store.dispatch("userStore/LOGOUT");
      this.$router.push("/dnbman");      
    },   
  },
};
</script>