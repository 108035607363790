import { instance } from '@/api/index';

function getCmpList(param) {
	return instance.get('/cmp/getCmpList', { params: param });
}
function getCmpInfo(param) {
	return instance.get('/cmp/getCmpInfo', { params: param });
}
function getAutoComplete(param) {
	return instance.get('/cmp/getAutoComplete', { params: param });
}
function getReportInfo(param) {
	return instance.get('/cmp/getReportInfo', { params: param });
}
export { getCmpList, getCmpInfo, getAutoComplete, getReportInfo };
