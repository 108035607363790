import { getCmpList } from '@/api/cmp';
const cmpStore = {
	namespaced: true,
	state: {
		searchWord : '',
        cmpList : '',
        listToTalCount: '',
        curPage: '',
        searchDetailCmpCd : '',
	},
	getters: {
		getSearchWord(state) {            
			return state.searchWord;
		},
        getCmpList(state){
            return state.cmpList;
        },
        getListTotalCont(state){
            return state.listToTalCount;
        },
        getCurPage(state){
            return state.curPage;
        }
	},
	mutations: {
		SET_SEARCH_WORD(state, param) {
			state.searchWord = param;
		},
        SET_CMP_LIST(state, param){
            state.cmpList = param;
        },
        SET_LIST_TOTAL_CNT(state, param){
            state.listToTalCount = param;
        },
        SET_CUR_PAGE(state, param){
            state.curPage = param;
        },
        SET_SEARCH_DETAIL_CD(state, param){
            state.searchDetailCmpCd = param;
        }
	},
	actions: {
		async GET_CMP_LIST({ commit }, param) {
			const { data } = await getCmpList(param);
			commit("SET_SEARCH_WORD", param.cmpNm);
            commit("SET_CMP_LIST", data.data.cmpList);
            commit("SET_CUR_PAGE", param.iPage);
            if(param.iPage==1){//첫 번째로 조회하는 경우만 총건수를 업데이트
                commit("SET_LIST_TOTAL_CNT", data.data.totalCnt);
            }
		},
        SET_SEARCH_WORD({ commit }, param){
            commit("SET_SEARCH_WORD", param);
        },
        SET_CUR_PAGE({ commit }, param){
            commit("SET_CUR_PAGE", param);
        },
        SET_SEARCH_DETAIL_CD({ commit}, param){
            commit("SET_SEARCH_DETAIL_CD", param);
        },
	},
};
export default cmpStore;