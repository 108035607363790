import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import Swal from "sweetalert2";

const routes = [
  {
    path: "/",
    component: () => import("@/views/MainPage.vue"),
    // meta: { authRequired: true }
  },
  {//로그인
    path: "/user/login",
    component: () => import("@/views/user/LoginPage.vue"),
  },
  {//회원가입
    path: "/user/signUp",
    component: () => import("@/views/user/SignUpPage.vue"),
  },
  {//아이디중복체크 팝업
    name: "idDupCheck",
    path: "/user/idDupCheck",
    component: () => import("@/views/user/IdDupCheckPopup.vue"),
  },
  {//주소검색 팝업
    name: "adrSearch",
    path: "/user/searchAdr",
    component: () => import("@/views/user/SearchAdrPopup.vue"),
  },
  {//회원가입성공
    name: "successJoinForm",
    path: "/user/successJoin",
    component: () => import("@/views/user/SuccessJoinForm.vue"),
  },
  {//아이디패스워드 찾기    
    path: "/user/find",
    component: () => import("@/views/user/FindPwdPage.vue"),
  },
  {//네비게이션 > 국내기업보고서
    path: "/cmp/dmiCmp",
    component: () => import("@/views/cmp/DomesticCmpRpt.vue"),
  },
  {//네비게이션 > 국내기업보고서 > 상세페이지
    path: "/cmp/dmiCmpDetail",
    component: () => import("@/views/cmp/DomesticCmpDetailPage.vue"),
  },
  {//보고서 샘플 팝업
    name: "reportInfo",
    path: "/cmp/getReportInfo",
    component: () => import("@/views/cmp/ReportInfoPopup.vue"),
  },
  {//네비게이션 > 해외기업보고서    
    path: "/forCmpRpt/:id",
    name: 'Foreign',
    component: () => import("@/views/ForeignCmpRptPage.vue"),
  },
  {//네비게이션 > 신설법인    
    path: "/corp",    
    component: () => import("@/views/InCorpDataPage.vue"),
  },
  {//네비게이션 > 신설법인 > 업종선택팝업
    name: "indSearch",
    path: "/corp/indSearch",
    component: () => import("@/views/IndSearchPopup.vue"),
  },
  {//네비게이션 > 마이페이지
    path: "/mypage",    
    component: () => import("@/views/my/MyPage.vue"),
  },
  {//마이페이지 > 장바구니
    path: "/shoppingCart",
    component: () => import("@/views/my/ShoppingCart.vue"),
  },
  {//마이페이지 > 내 구매내역
    path: "/myPurchaseHistory",
    component: () => import("@/views/my/MyPurchaseHistory.vue"),
  },
  {//마이페이지 > 내 구매내역
    path: "/upAlert",
    component: () => import("@/views/my/UpAlert.vue"),
  },
  {//마이페이지 > 회원정보수정전 비밀번호체크
    path: "/memberModifyPre",
    component: () => import("@/views/my/MemberModifyPre.vue"),
  },
  {//마이페이지 > 회원정보수정
    path: "/memberModify",
    component: () => import("@/views/my/MemberModify.vue"),
  },
  {//마이페이지 > 회원탈퇴
    path: "/secede",
    component: () => import("@/views/my/Secede.vue"),
  },
  {//마이페이지 > 캐쉬충전
    path: "/addCash",
    component: () => import("@/views/my/AddCash.vue"),
  },
  {//마이페이지 > 결제방식 선택
    path: "/settlement",
    component: () => import("@/views/my/Settlement.vue"),
  },
  {//마이페이지 > 나이스페이 결제 완료 결과페이지
    path: "/settlementDone",
    component: () => import("@/views/my/SettlementDone.vue"),
  },

  {//엑셀다운로드
    name: "incorpExcelDownload",
    path: "/incorpExcelDownload",
    component: () => import("@/views/my/IncorpExcelDownload.vue"),
  },
  {//마이페이지 > OZ리포트
    name: "ozStart",
    path: "/myPurchaseHistory/ozStart",
    component: () => import("@/views/my/OzStart.vue"),
  },
  //비회원 > 중계 페이지
  {    
    path: "/contents_ntt/step1_list_nr.do",
    component: () => import("@/views/guest/GuestMainPage.vue"),
  },
   //비회원 > 중계 페이지
  {
    name: "guestContent",
    path: "/contents_ntt/ntt_affi_NV",
    component: () => import("@/views/guest/GuestMainPage.vue"),
  },
  // 네이버 외부 연계를 위한 URL 추가
  {
    name: "guestContent_NV",
    path: "/contents_ntt/ntt_affi_NV.do",
    component: () => import("@/views/guest/GuestMainPage.vue"),
  },
  {    
    name: "guestcmpSearch",
    path: "/guest/guestDmiCmp",
    component: () => import("@/views/guest/GuestCmpRptPage.vue"),
  },
  {
    name: "guestcmpDetail_GNB",
    path: "/guest/guestDmiCmpDetail_GNB",
    component: () => import("@/views/guest/GuestCmpRptDetailPage.vue"),
  },
  {
    name: "guestcmpDetail",
    path: "/guest/guestDmiCmpDetail",
    component: () => import("@/views/guest/GuestCmpRptDetailPage.vue"),
  },
  {        
    path: "/contents_ntt/login_page_nv.do",
    component: () => import("@/views/guest/GuestOrderPage.vue"),
  },
  {    
    name: "guestOrder",
    path: "/guest/guestOrder",
    component: () => import("@/views/guest/GuestOrderPage.vue"),
  },
  {//비회원 > OZ리포트
    name: "guestOzView",
    path: "/contents_ntt/ntt_affi_HC.do",
    component: () => import("@/views/guest/GuestOzView.vue"),
  },
  // 예외
  {//관리자 > 로그인
    name: "admin",
    path: "/dnbman",
    component: () => import("@/views/admin/Login.vue"),
  },
  {//관리자메인
    name: "dnbman",
    path: "/dnbman/index",
    component: () => import("@/views/admin/Dnbman.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "order",
    path: "/dnbman/order",
    component: () => import("@/views/admin/Order.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "buyerInfo",
    path: "/dnbman/buyerInfo",
    component: () => import("@/views/admin/BuyerInfo.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "orderInfo",
    path: "/dnbman/orderInfo",
    component: () => import("@/views/admin/OrderInfo.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "cancel",
    path: "/dnbman/cancel",
    component: () => import("@/views/admin/Cancel.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "member",
    path: "/dnbman/member",
    component: () => import("@/views/admin/Member.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "memberInfo",
    path: "/dnbman/memberInfo",
    component: () => import("@/views/admin/MemberInfo.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "buyHistory",
    path: "/dnbman/buyHistory",
    component: () => import("@/views/admin/BuyHistory.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "cashHistory",
    path: "/dnbman/cashHistory",
    component: () => import("@/views/admin/CashHistory.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "updateAlarm",
    path: "/dnbman/updateAlarm",
    component: () => import("@/views/admin/UpdateAlarm.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "buyLog",
    path: "/dnbman/buyLog",
    component: () => import("@/views/admin/BuyLog.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "secret",
    path: "/dnbman/secret",
    component: () => import("@/views/admin/Secret.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "inputSecret",
    path: "/dnbman/inputSecret",
    component: () => import("@/views/admin/InputSecret.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "cpMonth",
    path: "/dnbman/cpMonth",
    component: () => import("@/views/admin/CpMonth.vue"),
    meta: { authRequired: true }
  },
  {//관리자-주문관리
    name: "cpAdmin",
    path: "/dnbman/cpAdmin",
    component: () => import("@/views/admin/CpAdmin.vue"),
    meta: { authRequired: true }
  },
  {//던스 팝업
    name: "dunsHelp",
    path: "/dunsHelp",
    component: () => import("@/views/cmp/DunsPopup.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  //  {
  //   path: "/ntt_main",
  //   component: () => import("@/views/MainPage2.vue"),
  //   // meta: { authRequired: true }
  // },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let alertMessage = "";
  if (to.matched.some(routeInfo => routeInfo.meta.authRequired)) {
    const isLogin = store.getters["userStore/isLogin"];
    if (!isLogin) {
      alertMessage = "로그인 후 이용하실 수 있습니다.";
      next("/");
    }else {      
      next();
    }
  } else {
    next();
  }

  if (alertMessage) {
    Swal.fire({
      position: "center",
      html: ` <div>
                <div>
                  <p>${alertMessage}</p>
                </div>
              </div>`,
      showConfirmButton: false,
      timer: 1000,
    });

  }
});

export default router;
